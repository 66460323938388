<template>
  <div class="ifrm_jump">
    <van-nav-bar :title="title" left-text left-arrow @click-left="onClickLeft"></van-nav-bar>
    <iframe
      :src="url"
      frameborder="0"
      class="height100"
      style="width: 100%"
      ref="maidIframe"
      id="maidIframe"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url:
        this.$route.query.url +
        (this.$route.query.url.indexOf('?') > -1 ? '&' : '?') +
        'v=' +
        Date.parse(new Date()),
      title: this.$route.query.title,
      time: 0
    }
  },
  created() {
    this.url =
      this.$route.query.url +
      (this.$route.query.url.indexOf('?') > -1 ? '&' : '?') +
      'v=' +
      Date.parse(new Date())
    if (localStorage.isYZJ === '1')
      this.url = this.url.replace('weixinlocal', 'yunzhijialocal')
    this.time = this.$route.query.time
  },
  methods: {
    onClickLeft() {
      this.$router.push('/report')
    }
  }
}
</script>
<style scoped lang="less">
.ifrm_jump {
  width: 100%;
  height: calc(100% - 1.89333rem);
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>